export class HttpCode {
  constructor(code) {
    this.code = code;
  }

  get color() {
    if (this.code >= 200 && this.code < 300) return "success";
    if (this.code >= 400) return "error";

    return "grey";
  }
}
