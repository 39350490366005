<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'logs-index' }" />

    <template v-if="log">
      <h2 class="mb-4">Лог (id: {{ id }})</h2>

      <v-sheet class="pa-4" elevation="3">
        <v-row class="props-list">
          <v-col md="2">ID</v-col>
          <v-col class="nobr" md="10">{{ log.id }}</v-col>

          <v-col md="2">Действие</v-col>
          <v-col md="10">{{ log.action }}</v-col>

          <v-col md="2">Пользователь</v-col>
          <v-col md="10">
            <router-link class="nobr" :to="{ name: 'users-id', params: { id: log.userId } }">
              {{ log.userName || `id: ${log.userId}` }}
            </router-link>
          </v-col>

          <v-col md="2">HTTP статус</v-col>
          <v-col md="10">
            <v-chip :color="pickHttpStatusColor(log)">
              {{ log.statusCode }}
            </v-chip>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="text-h6">Запрос</div>
        <code-block :value="log.data.request" />

        <v-divider class="my-3" />

        <div class="text-h6">Ответ</div>
        <code-block :value="log.data.response" />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Лог с id "{{ id }}" не найден</h2>
  </div>
</template>

<script>
import { HttpCode } from "~/libs/http-code";
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  data() {
    return {
      log: null,

      loading: true
    };
  },
  created() {
    this.fetchLog();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchLog() {
      this.loading = true;

      try {
        this.log = await this.$axios.$get(`logs/${this.id}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Лог с id: "${this.id}" не найден ${html}`);
      } finally {
        this.loading = false;
      }
    },

    pickHttpStatusColor({ statusCode }) {
      return new HttpCode(statusCode).color;
    }
  }
};
</script>
